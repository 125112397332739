import { ChangeEvent, FC, useState } from "react";
import Modal from "../Modal/Modal";
import { _isAfter, convertDMYtoYMD, formatPortDate } from "utils/dateTimeUtils";
import ModalFooter from "../ModalFooter";
import SelectDropdown from "../../SelectDropdown";
import { IMxpRestaurant } from "interfaces/MXP/IMxpRestaurant";
import { useGetRestaurantsSlots } from "modules/mxp/dining/hooks/useGetRestaurantsSlots";
import MxpAvailabilityOption from "modules/mxp/dining/MxpAvailabilityOption";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import Loader from "components/atoms/Loader";
import { usePostAddRestaurantToPlanner } from "modules/mxp/dining/hooks/usePostAddRestaurantToPlanner";
import Price from "components/Price/Price";
import { priceWithTwoDecimals } from "utils/cartUtils";
import useAppSelector from "hooks/useAppSelector";
import { IMxpMeal } from "interfaces/MXP/IMxpMeal";
import MxpMealTile from "modules/mxp/dining/MxpMealTile";

const LATEST_TIME_FOR_CHILD_DINING_BOOKING = "19:00";

type Props = {
  restaurant: IMxpRestaurant;
  date: string;
  onError: (value: string) => void;
  onSuccess: () => void;
  closeModal: () => void;
};

const minimumNumberOfAdults = 1;
const minimumNumberOfChildren = 0;

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

const MxpRestaurantAvailabilityModal: FC<Props> = ({
  restaurant,
  date,
  onError,
  onSuccess,
  closeModal,
}) => {
  const [nrAdultsSelected, setNrAdultsSelected] = useState(
    minimumNumberOfAdults
  );
  const [nrChildrenSelected, setNrChildrenSelected] = useState(
    minimumNumberOfChildren
  );

  const [selectedMeal, setSelectedMeal] = useState<IMxpMeal | null>(
    restaurant.meals.length === 1 ? restaurant.meals[0] : null
  );
  const [selectedAvailability, setSelectedAvailability] =
    useState<IMxpAvailabilitySlot | null>(null);

  const { data, isFetching: isLoading } = useGetRestaurantsSlots({
    venueId: restaurant.venueId,
    mealId: selectedMeal?.mealId,
    date: convertDMYtoYMD(date),
    numberOfPeople: nrAdultsSelected + nrChildrenSelected,
  });

  const { passengers } = useAppSelector((state) => state.preCruiseStatus);
  const nrAdultsOnCruise =
    passengers.filter((passenger) => "Adult" === passenger?.type)?.length ?? 0;
  const nrChildrenOnCruise =
    passengers.filter((passenger) => "Child" === passenger?.type)?.length ?? 0;

  // special rule for The Exchange events only, refer to CRUISE-503 task
  const THE_EXCHANGE_RESTAURANT = "The Exchange";
  const isExchangeRestaurant = restaurant.venueName.includes(
    THE_EXCHANGE_RESTAURANT
  );
  const adultsDropdownLabel = isExchangeRestaurant
    ? "Adults (18+)"
    : "Adults (13+)";

  const {
    postAddRestaurantToPlanner,
    postAddRestaurantToPlannerLoading,
    postAddRestaurantToPlannerIsSuccess,
    postAddRestaurantToPlannerError,
  } = usePostAddRestaurantToPlanner();

  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.id === "adults-dropdown") {
      setNrAdultsSelected(parseInt(e.target.value));
    } else {
      setNrChildrenSelected(parseInt(e.target.value));
    }

    setSelectedAvailability(null);
  };

  const handleSlotSelect = (availability: IMxpAvailabilitySlot) => {
    setSelectedAvailability(availability);
  };

  const addToCart = async () => {
    if (selectedAvailability) {
      await postAddRestaurantToPlanner({
        vacancy: selectedAvailability,
      });
    }
  };

  if (postAddRestaurantToPlannerIsSuccess) {
    onSuccess();
  }

  if (postAddRestaurantToPlannerError) {
    onError(postAddRestaurantToPlannerError?.message);
  }

  return (
    <Modal show={true} onClose={closeModal} title="Restaurant reservation">
      <>
        <div className="px-4 pt-2 text-blue-dark">
          <div className="text-lg border-b pb-2">
            {restaurant.venueName?.toUpperCase()}
          </div>
          <div className="text-grey-darker text-lg my-4">
            {formatPortDate(date)}
          </div>

          {/* display selected meal menu */}
          {selectedMeal && (
            <>
              <div className="my-4">How many people?</div>
              <div className="my-4 flex items-end">
                <SelectDropdown
                  classes="mr-4 w-1/2 md:w-40"
                  selectId="adults-dropdown"
                  dataTestIdName="adults-dropdown"
                  label={adultsDropdownLabel}
                  disabled={isLoading || postAddRestaurantToPlannerLoading}
                  error={false}
                  options={range(minimumNumberOfAdults, nrAdultsOnCruise)}
                  handleChange={handleDropdownChange}
                  value={nrAdultsSelected}
                />

                {!isExchangeRestaurant && nrChildrenOnCruise > 0 && (
                  <SelectDropdown
                    classes="w-1/2 md:w-40"
                    selectId="children-dropdown"
                    dataTestIdName="children-dropdown"
                    label="Children (2-12 years)"
                    disabled={isLoading || postAddRestaurantToPlannerLoading}
                    error={false}
                    options={range(minimumNumberOfChildren, nrChildrenOnCruise)}
                    handleChange={handleDropdownChange}
                    value={nrChildrenSelected}
                  />
                )}
              </div>

              {nrChildrenSelected > 0 && (
                <div className="bg-grey-lighter px-4 py-3">
                  Family-friendly dining is between 18:00 and 19:00.
                </div>
              )}

              {/* loading availabilities */}
              {isLoading && (
                <Loader text="Searching for available time slots..." />
              )}

              {/* loading availabilities */}
              {postAddRestaurantToPlannerLoading && (
                <Loader text="Adding to planner..." />
              )}

              {/* no error and availabilities loaded*/}
              {!isLoading && !postAddRestaurantToPlannerLoading && (
                <div>
                  <div className="my-4">
                    What time would you like your table?
                  </div>

                  {data?.length === 0 && (
                    <div className="bg-grey-lighter px-4 py-3">
                      Unfortunately, we don’t have any tables available for your
                      dining party to book at this time. Please enquire about
                      your dining options onboard.
                    </div>
                  )}
                  <div className="mt-4 md:flex md:flex-wrap md:-mx-2">
                    {data
                      ?.sort((a, b) => {
                        return a.AvailableTime.localeCompare(b.AvailableTime);
                      })
                      .map((availability) => (
                        <MxpAvailabilityOption
                          key={availability.VacancyId}
                          availability={availability}
                          isSelected={
                            selectedAvailability?.VacancyId ===
                            availability.VacancyId
                          }
                          isDisabled={
                            nrChildrenSelected > 0 &&
                            _isAfter(
                              availability.AvailableTime,
                              LATEST_TIME_FOR_CHILD_DINING_BOOKING
                            )
                          }
                          onClick={() => handleSlotSelect(availability)}
                        />
                      ))}
                  </div>
                </div>
              )}

              {/* Dietary restrictions / allergies */}
              {!isLoading &&
                !postAddRestaurantToPlannerLoading &&
                data?.length > 0 && (
                  <div className="border-b border-t py-2 flex justify-between items-center">
                    {/* total cost summary */}
                    <div className="uppercase font-bold">Total cost</div>
                    <Price
                      price={
                        selectedAvailability
                          ? priceWithTwoDecimals(
                              selectedAvailability?.totalAmount?.toString()
                            )
                          : "0.00"
                      }
                    />
                  </div>
                )}
            </>
          )}

          {/* display a list of tiles of meal menu */}
          {selectedMeal === null && restaurant.meals.length > 1 && (
            <>
              <div>Select a Meal Menu:</div>

              <div className="flex">
                {restaurant.meals.map((meal) => (
                  <MxpMealTile
                    key={meal.mealId}
                    meal={meal}
                    onClick={() => setSelectedMeal(meal)}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <ModalFooter
          onCancel={closeModal}
          actionDisabled={
            selectedAvailability === null ||
            postAddRestaurantToPlannerLoading ||
            isLoading
          }
          actionButtonText="Add To Planner"
          onActionClick={addToCart}
        />
      </>
    </Modal>
  );
};

export default MxpRestaurantAvailabilityModal;

import { useCallback, useEffect } from "react";
import AgencyTokenLogin from "./modules/login/AgencyTokenLogin";
import PassengerTokenLogin from "./modules/login/PassengerTokenLogin";
import PassengerLoginForm from "./modules/login/PassengerLoginForm";
import PassengerLogout from "./modules/login/PassengerLogout";
import AgencyLogout from "./modules/login/AgencyLogout";
import NotFoundModule from "./modules/notfound";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./modules/overview/Dashboard";
import Itinerary from "./modules/overview/Itinerary";
import ExcursionsList from "./modules/excursions/ExcursionsList";
import ExcursionDetail from "./modules/excursions/ExcursionDetail";
import PassengerDetails from "./modules/checkout/PassengerDetails";
import RestaurantsList from "./modules/tac/RestaurantsList";
import PaymentConfirmationB2C from "./modules/checkout/PaymentConfirmationB2C";
import {
  updateBookings,
  updateOswBookings,
  updateMusementBookings,
  updateMxpBookings,
} from "./actions/cartActions";
import AuthRoute from "./components/routing/AuthRoute";
import GuestRoute from "./components/routing/GuestRoute";
import AnyAuthRoute from "./components/routing/AnyAuthRoute";
import PaymentConfirmationB2B from "./modules/checkout/PaymentConfirmationB2B";
import AgencySSORedirect from "./modules/login/AgencySSORedirect";
import PrecruiseRoutes from "./modules/routes/PreCruiseRoutes";
import BoardingPass from "./modules/boarding/BoardingPass";
import BoardingPassDetail from "./modules/boarding/BoardingPassDetail";
import LuggageTag from "./modules/luggage/LuggageTag";
import LuggageTagDetail from "./modules/luggage/LuggageTagDetail";
import Instructions from "./modules/luggage/Instructions";
import InstructionsPrintable from "./modules/luggage/InstructionsPrintable";
import ApisForm from "./modules/apis/ApisForm";
import PaymentConfirmationGPP from "./modules/checkout/PaymentConfirmationGPP";
import PaymentGPP from "./modules/checkout/PaymentGPP";
import PaymentFailure from "./modules/checkout/PaymentFailure";
import OneSpaWorldList from "modules/oneSpaWorld/OneSpaWorldList";
import OneSpaWorldDetails from "modules/oneSpaWorld/OneSpaWorldDetails";
import MusementExcursionsList from "modules/musement/MusementExcursions/MusementExcursionsList";
import AppInfo from "modules/admin/AppInfo";
import MusementExcursionDetail from "modules/musement/MusementExcursions/MusementExcursionDetail";
import { useUpdateCart } from "actions/useUpdateCart";
import useAppSelector from "hooks/useAppSelector";
import ScrollToTop from "hocs/ScrollToTop";
import MxpRestaurantsList from "modules/mxp/dining/MxpRestaurantsList";
import PaymentConfirmationComplimentary from "modules/checkout/PaymentConfirmationComplimentary";
import PaymentFailureDiningUnavailability from "modules/checkout/PaymentFailureDiningUnavailability";

const Routes = () => {
  const { updateCart } = useUpdateCart();
  const isAuthenticated = useAppSelector(
    (state) => state.authentication.isAuthenticated
  );

  const updateEverything = useCallback(async () => {
    await updateCart();
    await updateBookings();
    await updateOswBookings();
    await updateMusementBookings();
    await updateMxpBookings();
  }, []);

  useEffect(() => {
    const loginPaths = RegExp("^/(token|login|agencyToken|passengerToken)");

    if (isAuthenticated && !loginPaths.test(window.location.pathname)) {
      updateEverything();
    }
  }, [isAuthenticated, updateEverything]);

  return (
    <ScrollToTop>
      <Switch>
        <Route path="/agent" component={AgencySSORedirect} />

        <GuestRoute path="/login" component={PassengerLoginForm} />
        <Route path="/logoutUser" component={PassengerLogout} />
        <Route path="/logoutAgency/:forgetStore?" component={AgencyLogout} />

        <Route path="/token/:token" component={AgencyTokenLogin} />
        <Route path="/passengerToken/:token" component={PassengerTokenLogin} />

        <AuthRoute exact path="/" component={Dashboard} />
        <AuthRoute path="/dashboard" component={Dashboard} />

        <Route path="/precruise" component={PrecruiseRoutes} />

        <Route path="/boarding/detail" component={BoardingPassDetail} />
        <Route path="/boarding" component={BoardingPass} />
        <Route path="/luggage/detail" component={LuggageTagDetail} />
        <Route path="/luggage/instructions" component={Instructions} />
        <Route
          path="/luggage/printableInstructions"
          component={InstructionsPrintable}
        />
        <Route path="/luggage" component={LuggageTag} />

        <Route path="/apis" component={ApisForm} />

        <AuthRoute path="/itinerary" component={Itinerary} />

        {/* Excursions (MXP) */}

        <AuthRoute path="/excursions/:itineraryId" component={ExcursionsList} />

        <AuthRoute
          path="/excursion/:itineraryId/:excursionId/:modal?"
          component={ExcursionDetail}
        />

        {/* Excursions (Musement) */}

        <AuthRoute
          path="/musement/excursions/:itineraryId"
          component={MusementExcursionsList}
        />

        <AuthRoute
          path="/musement/excursion/:itineraryId/:activityId"
          component={MusementExcursionDetail}
        />

        {/* Tac (Dinning) Routes */}

        <AuthRoute path="/tac/restaurants" component={RestaurantsList} />
        <AuthRoute
          path="/mxp/restaurants/:itineraryDate"
          component={MxpRestaurantsList}
        />

        {/* Spa & Wellness Routes */}

        <AuthRoute
          path="/spa-and-wellness/:itineraryId"
          component={OneSpaWorldList}
        />

        <AuthRoute
          path="/spa-treatment/date/:spaTreatmentDate/itinerary-id/:itineraryId/by-id/:spaTreatmentId"
          component={OneSpaWorldDetails}
        />

        <AuthRoute path="/checkout" component={PassengerDetails} />
        <AnyAuthRoute
          path="/confirmation/b2b"
          component={PaymentConfirmationB2B}
        />
        <AuthRoute
          path="/destinations/book/confirmation"
          component={PaymentConfirmationB2C}
        />
        <AuthRoute path="/payment" component={PaymentGPP} />
        <AuthRoute
          path="/book/confirmation"
          component={PaymentConfirmationGPP}
        />
        <AuthRoute
          path="/confirmation/complimentary"
          component={PaymentConfirmationComplimentary}
        />
        <AuthRoute path="/book/failure" component={PaymentFailure} />
        <AuthRoute
          path="/book/failure-unavailability"
          component={PaymentFailureDiningUnavailability}
        />

        <AuthRoute path="/admin/info" component={AppInfo} />

        <AuthRoute path="*" component={NotFoundModule} />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
